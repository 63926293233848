/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const AiSummaryCard = styled.div`
  border: 1px solid var(--border);
  padding: 1rem;
  border-radius: 0.8rem;
  ${space}
`;
