import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import PrimaryText from '@ui-kit/typography/primaryText';
import SecondaryText from '@ui-kit/typography/secondaryText';
import Icon from '@ui-kit/icon';
import AiIcon from '@assets/icons/ai-icon.svg';

import { AiSummaryCard } from './AiSummaryStyles';

function AiSummary({ summary, ...restProps }) {
  return (
    <AiSummaryCard {...restProps}>
      <Box display="flex" alignItems="center" mb="0.5rem">
        <Icon color="var(--text-primary)" size="0.875rem" mr="0.5rem">
          <AiIcon />
        </Icon>
        <PrimaryText color="var(--text-primary)" lineHeight="1">AI Summary</PrimaryText>
      </Box>
      <SecondaryText color="var(--text-primary)" size="0.875rem">{summary}</SecondaryText>
    </AiSummaryCard>
  );
}

AiSummary.propTypes = {
  summary: PropTypes.string.isRequired,
};

export default AiSummary;
