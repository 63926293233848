import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import AppState from '@state';
import { route } from 'preact-router';

import { getOrgArtistInvites, patchOrgArtistInviteResend, deleteOrgArtistInvite } from '@api/restricted/org-artist-invite-api';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { useTimeAgo } from '@hooks/useTimeAgo';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Icon from '@ui-kit/icon';
import MenuIcon from '@assets/icons/menu-elip.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import InputLabel from '@ui-kit/inputs/inputLabel';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { PUB_WRITER_EXPLANATIONS } from '@constants/supportingCopy';

import { TableSearchEmptyPrompt, StyledMenuItem, StyledOptionButton } from './PendingSongwritersStyles';

function PendingSongwriters({ addNew }) {
  const [isLoadingWriters, setIsLoadingWriters] = useState(true);
  const [inviteToCancel, setInviteToCancel] = useState();
  const [inviteToResend, setInviteToResend] = useState();
  const [isUpdateEmail, setIsUpdateEmail] = useState(false);
  const [emailToUpdate, setEmailToUpdate] = useState(null);
  const [activeMenuInvite, setActiveMenuInvite] = useState(null);
  const [isResending, setIsResending] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [error, setError] = useState();
  const [searchActive, setSearchActive] = useState(false);
  const [allInvitations, setAllInvitations] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.organizationSongwriter.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const menuRef = useRef(null);
  const pageRef = useRef(null);

  const useToastAlertSuccess = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  const loadInvitedWriters = async () => {
    try {
      const response = await getOrgArtistInvites(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading invited writers`;
        route('/error/exception', true);
      } else {
        const json = await response.json();
        const formattedInvites = json.invites.map((invite) => ({
          ...invite,
          expirationDate: (new Date(Date.parse(invite.updatedDate) + 30 * 24 * 60 * 60 * 1000)).toISOString(), // 30 days
        }));
        setAllInvitations(formattedInvites);
        setInvitations(formattedInvites);
        setSearchActive(false);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading invited writers`;
      route('/error/exception', true);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveMenuInvite(null);
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    setIsLoadingWriters(true);
    loadInvitedWriters().then(() => {
      setTimeout(() => {
        setIsLoadingWriters(false);
      }, 800);
    });

    const handleScroll = () => {
      if (menuRef.current) {
        setActiveMenuInvite(null);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = invitations.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      invitations.forEach((w) => {
        if (w.email.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(w);
        }
      });
      setInvitations(results);
      setSearchActive(true);
    } else {
      setInvitations(allInvitations);
      setSearchActive(false);
    }
  };

  const handleCancelInvite = () => {
    setError('');
    setIsCanceling(true);

    deleteOrgArtistInvite(AppState.pubOrganization.id.value, inviteToCancel.id)
      .then((response) => {
        if (response.status === 200) {
          loadInvitedWriters().then(() => {
            setError('');
            setIsCanceling(false);
            setInviteToCancel(null);
          });
        } else {
          setIsCanceling(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsCanceling(false);
        setError(err.message);
      });
  };

  const onClickResend = (invite, options) => {
    if (options.updateEmail) {
      setEmailToUpdate(invite.email);
      setIsUpdateEmail(true);
    }
    setInviteToResend(invite);
  };

  const handleResendEmail = () => {
    setError('');
    setIsResending(true);

    patchOrgArtistInviteResend(AppState.pubOrganization.id.value, inviteToResend.id, (emailToUpdate === inviteToResend.email ? null : emailToUpdate))
      .then((response) => {
        if (response.status === 200) {
          loadInvitedWriters().then(() => {
            setError('');
            setIsResending(false);
            setInviteToResend(null);
            setIsUpdateEmail(false);
            setEmailToUpdate(null);
            useToastAlertSuccess('Writer Invitation Resent Successfully!');
          });
        } else {
          setIsResending(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsResending(false);
        setError(err.message);
      });
  };

  const composeDripMessage = (lastSentDate, remindersSent) => {
    if (remindersSent === 0) {
      const nextDate = Date.parse(lastSentDate) + 7 * 24 * 60 * 60 * 1000;
      const timeString = useTimeAgo(nextDate);
      return `1st Reminder ${timeString}`;
    }
    if (remindersSent === 1) {
      const nextDate = Date.parse(lastSentDate) + 14 * 24 * 60 * 60 * 1000;
      const timeString = useTimeAgo(nextDate);
      return `2nd Reminder ${timeString}`;
    }
    const timeString = useTimeAgo(lastSentDate);
    return `Last Reminder Sent ${timeString}`;
  };

  const handleMenuClick = (invite) => {
    setActiveMenuInvite(invite);
  };

  const closeFunction = () => {
    setInviteToCancel(null);
    setInviteToResend(null);
    setIsUpdateEmail(false);
    setEmailToUpdate(null);
    setError('');
  };

  return (
    isLoadingWriters ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />
        {!searchActive && invitations.length === 0
          ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Pending Invitations"
                message="To add a writer, craft a credential and send them a request"
                action={addNew}
                actionText="Add Songwriter"
                illustration="personas"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              <TableControls
                itemName="Invitation"
                tableData={allInvitations}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchPlaceholder="Search by email..."
                searchActive={searchActive}
                searchResults={invitations}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="28%" pr="0.75em">Email</TableCell>
                  <TableCell>Writer Name</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell>Automated Reminder</TableCell>
                  <TableCell>{' '}</TableCell>
                </TableHeader>
                {currentResults.map((invite) => (
                  <TableRow>
                    <TableCell widthPercent="28%" pr="0.75em">
                      {invite.email}
                    </TableCell>
                    <TableCell mobileLabel="Writer Name:" color={!invite.firstName && 'var(--text-medium-mute)'}>
                      {invite.firstName || '(Not Registered)'}
                      {' '}
                      {invite.lastName || ''}
                    </TableCell>
                    <TableCell mobileLabel="Sent:">{d(invite.createdDate)}</TableCell>
                    <TableCell mobileLabel="Automated Reminder:">
                      {composeDripMessage(invite.updatedDate, invite.remindersSent)}
                    </TableCell>
                    <TableCell>
                      <Box position="relative" display="flex" justifyContent="flex-end">
                        <StyledOptionButton onClick={() => handleMenuClick(invite)}>
                          <Icon size="1.2em" pt="0.25em" pr="0.25em" cursor><MenuIcon /></Icon>
                        </StyledOptionButton>
                        {activeMenuInvite === invite && (
                          <PopoverMenu ref={menuRef} transform="translate(10px,10px)" style={{ position: 'fixed' }}>
                            <StyledMenuItem onClick={() => onClickResend(invite, { updateEmail: false })}>Resend</StyledMenuItem>
                            <StyledMenuItem onClick={() => onClickResend(invite, { updateEmail: true })}>Update Email</StyledMenuItem>
                            <StyledMenuItem onClick={() => setInviteToCancel(invite)}>Cancel Invite</StyledMenuItem>
                          </PopoverMenu>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {(searchActive && currentResults.length === 0)
                  && (
                    <TableSearchEmptyPrompt>
                      <Text color="var(--text-muted-panel)">No Invitations Match Your Search</Text>
                    </TableSearchEmptyPrompt>
                  )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={invitations.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

        {inviteToCancel && (
        <DeleteConfirmationOverlay
          headerLabel="Are you sure?"
          header="Cancel Request to Writer"
          explanation={PUB_WRITER_EXPLANATIONS.cancelInvitation}
          closeFunction={closeFunction}
          handleDelete={handleCancelInvite}
          error={error}
          isLoading={isCanceling}
          confirmationPhrase={inviteToCancel.email}
          buttonText="Cancel Request"
        />
        )}

        {inviteToResend && (
        <WorkspaceEditOverlay
          closeFunction={closeFunction}
        >
          <Text mt="3rem" fontSize="1.5em" mb="0.5" fontFamily="var(--font-secondary)" color="var(--text-primary)" fontWeight="800">
            {isUpdateEmail ? 'Update Email and Resend' : 'Resend Songwriter Invite'}
          </Text>
          <Text fontSize="0.813rem" mb="1.5rem" fontFamily="var(--font-secondary)" color="var(--text-secondary)" fontWeight="400">
            This will resend the invite to the writer immediately. We will also send reminder emails periodically.
          </Text>
          {isUpdateEmail && (
          <Box>
            <InputLabel label="Invitation Email" />
            <BaseInput
              id="emailToResend"
              name="emailToResend"
              placeholder="Email..."
              onChange={(e) => setEmailToUpdate((e.target.value))}
              defaultValue={emailToUpdate}
              fluid
              mb="2.5em"
            />
          </Box>
          )}
          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
          <BaseButton
            mb="1.5em"
            type="submit"
            btnText="Resend Now"
            disabled={isUpdateEmail && !emailToUpdate}
            fluid
            isLoading={isResending}
            onClick={handleResendEmail}
          />
        </WorkspaceEditOverlay>
        )}
      </Box>
    )
  );
}

PendingSongwriters.propTypes = {
  addNew: PropTypes.func.isRequired,
};

export default PendingSongwriters;
