/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unescaped-entities */
import { route } from 'preact-router';
import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'preact/hooks';
import PropTypes from 'prop-types';
import config from '@config';
import { Fragment } from 'preact';
import dayjs from 'dayjs';

import AppState from '@state/AppState';

import loadOrganization from '@actions/loadOrganization';
import loadOrgRegistrations from '@actions/loadOrgRegistrations';
import { postOrgContract } from '@api/restricted/org-contracts-api';

import useScript from '@hooks/useScript';
import useToastAlertError from '@hooks/useToastAlertError';
import useRoundNumber from '@hooks/useRoundNumber';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import SecondaryText from '@ui-kit/typography/secondaryText';
import WizardProgress from '@distinct-components/feedback/wizardProgress';

import AiLoadingAnimation from '@components/animations/ai/AiLoadingAnimation';

import AddWriterDetails from './sections/addWriterDetails';
import AddWriterTerms from './sections/addWriterTerms';
import AddWriterRequirements from './sections/addWriterRequirements';
import AddWriterCoPub from './sections/addWriterCoPub';
import ReviewWriterInvitation from './sections/reviewWriterInvitation';
import SelectCredentialEditType from './sections/selectCredentialEditType';

const ipiRegex = /^[0-9]{9,11}$/;

function PublisherAddSingleWriter({ uuid }) {
  const placesSrc = `https://maps.googleapis.com/maps/api/js?key=${config.google.placesKey}&libraries=places`;
  const placesState = useScript(placesSrc);
  const [step, setStep] = useState('writer');
  const [progressWidth, setProgressWidth] = useState(10);
  const [progressSubHeader, setProgressSubHeader] = useState('Get Started');

  const [contractFile, setContractFile] = useState(null);
  const [contractData, setContractData] = useState(null);
  const [isAnalyzingContract, setIsAnalyzingContract] = useState(false);

  const [editType, setEditType] = useState();

  const [recipient, setRecipient] = useState();
  const [terms, setTerms] = useState();
  const [requirements, setRequirements] = useState();
  const [coPub, setCoPub] = useState();

  const isEditingCredential = !!AppState.publisherEditingCredential.id.value;

  const pageRef = useRef(null);

  const useToastAlertSuccess = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  useEffect(() => {
    document.title = 'Publisher - Add Writer';
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadOrgRegistrations(AppState.pubOrganization.id.value, { forcedLoad: false }).then(() => {
        if (isEditingCredential) {
          setRecipient(AppState.publisherEditingCredential.recipient.value);
          setTerms(AppState.publisherEditingCredential.terms.value);
          setRequirements(AppState.publisherEditingCredential.requirements.value);
          setStep('editType');
        }
      });
    });
  }, []);

  useLayoutEffect(() => {
    pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    switch (step) {
      case 'review':
        setProgressWidth(100);
        setProgressSubHeader('Ready to Send');
        break;
      case 'commitment':
        setProgressWidth(80);
        break;
      case 'copub':
        setProgressWidth(60);
        break;
      case 'terms':
        setProgressWidth(40);
        if (recipient?.user) {
          setProgressSubHeader(`${recipient.user?.firstName} ${recipient.user?.lastName}`);
        } else {
          setProgressSubHeader(recipient?.email);
        }
        break;
      default:
        setProgressWidth(20);
    }
  }, [step]);

  const handleCancelInvitation = () => {
    route(`/publisher/${uuid}/songwriters`);
  };

  const onClickStepBack = () => {
    switch (step) {
      case 'review':
        setStep('commitment');
        break;
      case 'commitment':
        if (!isEditingCredential && (!terms?.hasOwnership || terms?.ownershipShare < 100)) {
          setStep('copub');
        } else {
          setStep('terms');
        }
        break;
      case 'copub':
        setStep('terms');
        break;
      case 'terms':
        if (isEditingCredential) {
          setStep('editType');
        } else {
          setStep('writer');
        }
        break;
      default:
        setProgressWidth(30);
    }
  };

  const handleAnalyzeContract = (currentWriterDetails) => {
    setIsAnalyzingContract(true);
    setContractData(null);

    postOrgContract(AppState.pubOrganization.id.value, contractFile)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setContractFile(null);
          setIsAnalyzingContract(false);
        } else {
          response.json().then((json) => {
            const { data } = json;
            const writer = data.writers[0];
            const { publisher } = data;
            const mdr = data.deliveryCommitment;

            const writerSocietyToUse = writer.pro || writer.selfPublisher?.pro || publisher.pro;
            const publisherSocietyNameToUse = publisher.pro?.name || writer.pro?.name || writer.selfPublisher?.pro?.name;
            const writerSelfPubSocietyNameToUse = writer.selfPublisher?.pro?.name || writer.pro?.name || publisher.pro?.name;

            setRecipient({
              ...currentWriterDetails,
              ...(writerSocietyToUse && { suggestedSociety: { name: writerSocietyToUse.name, country: writerSocietyToUse.country, societyType: 'pro' } }),
              ...(ipiRegex.test(writer.ipi) && { suggestedIPI: writer.ipi }),
            });

            // Only update registration if one has been chosen. Else, leave it to the logic in the Terms component
            let publisherRegistrationsMatched = [];
            if (terms?.publisherRegistrationId) {
              publisherRegistrationsMatched = publisherSocietyNameToUse
                ? AppState.orgRegistrations.value.filter((reg) => reg.society === publisherSocietyNameToUse)
                : [];
            }

            setTerms({
              ...terms,
              compositionsControlled: (data.isTermDeal ? 'all' : 'some'),
              hasEndDate: !!data.endDate,
              ...(data.startDate && { fromDate: dayjs(data.startDate, 'MM/DD/YYYY').format() }),
              ...(data.endDate && { toDate: dayjs(data.endDate, 'MM/DD/YYYY').format() }),
              ...(publisherRegistrationsMatched.length === 1 && {
                publisherRegistrationId: publisherRegistrationsMatched[0]?.id || '',
              }),
              hasOwnership: data.ownershipPercentage > 0,
              ownershipShare: data.ownershipPercentage > 0 ? useRoundNumber(data.ownershipPercentage, 2) : 0,
              territory: data.territory,
            });

            if (writer.selfPublisher?.name) {
              setCoPub({
                ...coPub,
                detailProvided: true,
                name: writer.selfPublisher.name,
                ...(writerSelfPubSocietyNameToUse && { society: writerSelfPubSocietyNameToUse }),
                ...(ipiRegex.test(writer.selfPublisher.ipi) && { ipi: writer.selfPublisher.ipi }),
                ...(writer.selfPublisher.address && { address: writer.selfPublisher.address }),
              });
            }

            setRequirements({
              ...requirements,
              ...(mdr?.included && {
                detailProvided: true,
                totalWorks: mdr?.totalCompositions > 0 ? mdr?.totalCompositions : 0,
                qualifyingPercentage: mdr?.qualifyingPercentage > 0 ? mdr?.qualifyingPercentage : 0,
                allowCombineWorks: !!mdr?.aggregation?.allowed,
                hasMinimum: mdr?.aggregation?.minimumPercentage > 0,
                minimumPercentage: mdr?.aggregation?.minimumPercentage > 0 ? mdr?.aggregation?.minimumPercentage : 0,
              }),
            });

            const fieldsPopulated = [
              !!writerSocietyToUse,
              ipiRegex.test(writer.ipi),
              true, // compositionsControlled is always populated
              true, // hasEndDate is always populated
              !!data.startDate,
              !!data.endDate,
              publisherRegistrationsMatched.length === 1,
              true, // hasOwnership is always populated
              data.ownershipPercentage > 0,
              !!data.territory,
              !!writer.selfPublisher?.name, // coPub detailsProvided
              !!writer.selfPublisher?.name, // coPub name
              !!writer.selfPublisher?.name && !!writerSelfPubSocietyNameToUse,
              !!ipiRegex.test(writer.selfPublisher?.ipi),
              !!writer.selfPublisher?.address,
              !!mdr?.included, // requirements detailProvided
              mdr?.totalCompositions > 0,
              mdr?.qualifyingPercentage > 0,
              !!mdr?.included, // requirements allowCombineWorks
              !!mdr?.aggregation?.allowed, // requirements hasMinimum
              mdr?.aggregation?.minimumPercentage > 0,
            ].filter((isPopulated) => isPopulated).length;

            setContractData({
              fieldsPopulated,
              data: {
                writerPRO: writerSocietyToUse?.name || null,
                writerIPI: ipiRegex.test(writer?.ipi) ? writer.ipi : null,
              },
            });

            useToastAlertSuccess('Contract Data Applied');
            setIsAnalyzingContract(false);
          });
        }
      })
      .catch((err) => {
        setIsAnalyzingContract(false);
        setContractFile(null);
        useToastAlertError(err.message);
      });
  };

  return (
    <FullScreenModal formWidth="24em" closeFunction={handleCancelInvitation}>
      <div ref={pageRef} />
      {isAnalyzingContract ? (
        <Box mt="4rem" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <AiLoadingAnimation />
          <SecondaryText mt="1.5em" fontSize="0.813rem">
            Analyzing Contract...
          </SecondaryText>
        </Box>
      ) : (
        <Fragment>
          <Box mb="1em">
            {{
              editType: <Box mb="0.25em">
                <Header mb="0.25em">Edit Credential</Header>
                <Text>Determine if the changes should apply to past compositions or only compositions moving forward.</Text>
              </Box>,
              writer: <Box>
                <Header mb="0.25em">Get Started</Header>
                <Text>Enter your writer’s email.  If they do not have an account, we will send your invite and get them signed up.</Text>
              </Box>,
              terms: <Box>
                <Header mb="0.25em">Credential Terms</Header>
                <Text>If accepted, this credential info will be added to their profile and used on compositions they create.</Text>
              </Box>,
              copub: <Box mb="0.25em">
                <Header mb="0.25em">{terms?.hasOwnership ? 'Co-Pub Information' : 'Writer Publisher Info'}</Header>
                <Text>If the Writer’s self-owned publisher owns the remaining percentage, you can help define that info below.</Text>
              </Box>,
              commitment: <Box>
                <Header mb="0.25em">Commitment Tracking</Header>
                <Text>You can monitor the progress of works delivered by adding commitment requirements to the credential.</Text>
              </Box>,
              review: <Box mb="0.25em">
                <Header mb="0.25em">Review and Send</Header>
                <Text>Confirm the information is correct and send the invite.  You can monitor invites from the “pending” tab.</Text>
              </Box>,
            }[step]}
          </Box>

          {step !== 'editType' && (
          <WizardProgress
            headerText="Invite Writer"
            subHeaderText={progressSubHeader || 'WRITER_NAME_EMAIL'}
            progressWidth={progressWidth}
            handleStepBack={onClickStepBack}
            showBackButton={step !== 'writer' && step !== 'editType'}
            progressIcon="send"
            mb="1.5em"
          />
          )}

          <Box>
            {{
              editType: <SelectCredentialEditType setStep={setStep} editType={editType} setEditType={setEditType} />,
              writer: (
                <AddWriterDetails
                  setStep={setStep}
                  contractFile={contractFile}
                  setContractFile={setContractFile}
                  contractData={contractData}
                  setContractData={setContractData}
                  isAnalyzingContract={isAnalyzingContract}
                  handleAnalyzeContract={handleAnalyzeContract}
                  recipient={recipient}
                  setRecipient={setRecipient}
                />
              ),
              terms: <AddWriterTerms setStep={setStep} recipient={recipient} terms={terms} setTerms={setTerms} />,
              copub: <AddWriterCoPub setStep={setStep} terms={terms} coPub={coPub} setCoPub={setCoPub} placesState={placesState} />,
              commitment: <AddWriterRequirements setStep={setStep} requirements={requirements} setRequirements={setRequirements} />,
              review: (
                <ReviewWriterInvitation
                  setStep={setStep}
                  editType={editType}
                  recipient={recipient}
                  terms={terms}
                  requirements={requirements}
                  coPub={coPub}
                />),
            }[step]}
          </Box>
        </Fragment>
      )}

    </FullScreenModal>
  );
}

PublisherAddSingleWriter.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherAddSingleWriter;
