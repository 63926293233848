/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useCallback, useState, useRef, useEffect,
} from 'preact/hooks';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { Fragment } from 'preact';

import { getArtistLookupWithEmail } from '@api/restricted/artist-lookup-api';

import useDebounce from '@hooks/useDebounce';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import BaseButton from '@ui-kit/buttons/baseButton';
import Spinner from '@ui-kit/loaders/Spinner';
import BreakLine from '@ui-kit/dividers/breakLine';
import Avatar from '@ui-kit/avatar';
import AlertMessage from '@ui-kit/alert/Alert';
import SecondaryText from '@ui-kit/typography/secondaryText';
import DragAndDrop from '@distinct-components/files/dragAndDrop';

// import Icon from '@ui-kit/icon';
// import TrashIcon from '@assets/icons/trash-outline.svg';

import MailWedge from '@animations/wedges/MailWedge';

import { SOCIETIES } from '@constants/collectionSocieties';

const ipiRegex = /^[0-9]{9,11}$/;
const isniRegex = /^[0-9]{16}$/;

function AddWriterDetails({
  contractFile,
  setContractFile,
  contractData,
  setContractData,
  isAnalyzingContract,
  handleAnalyzeContract,
  setRecipient,
  recipient,
  setStep,
  ...restProps
}) {
  const [emailToInvite, setEmailToInvite] = useState();
  const [existingUser, setExistingUser] = useState(null);
  const [suggestedSociety, setSuggestedSociety] = useState(null); // Name string only
  const [suggestedIPI, setSuggestedIPI] = useState(null);
  const [suggestedISNI, setSuggestedISNI] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const [error, setError] = useState('');
  const [contractError, setContractError] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const searchRef = useRef(null);
  const inputIPI = useRef(null);
  const inputISNI = useRef(null);
  const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');

  const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');
  const getSocietyByName = (societyName) => {
    if (!societyName) {
      return null;
    }
    const found = WriterSocieties.find((s) => s.name === societyName);
    return found ? ({
      name: found.name,
      country: found.country,
      type: found.societyType,
    }) : null;
  };

  useEffect(() => {
    if (recipient) {
      if (recipient.email) {
        searchRef.current.value = recipient.email;
        setEmailToInvite(recipient.email);
      }

      if (recipient.user?.id) {
        setExistingUser(recipient.user);
      }

      const lastSociety = recipient.suggestedSociety?.name || recipient.user?.society;
      setSuggestedSociety(lastSociety);

      const lastIPI = recipient.suggestedIPI || recipient.user?.ipi;
      setSuggestedIPI(lastIPI);

      const lastISNI = recipient.suggestedISNI || recipient.user?.isni;
      setSuggestedISNI(lastISNI);

      if (recipient.email) {
        setTimeout(() => {
          inputIPI.current.value = lastIPI || null;
          inputISNI.current.value = lastISNI || null;
        }, 200);
      }
    }
  }, []);

  const handleAddContractFile = (files) => {
    setContractError(null);
    const fileToAdd = files[0];
    if (!fileToAdd || !fileToAdd.name.endsWith('.pdf')) {
      setContractError('Only PDF files are supported');
    } else {
      setContractFile(fileToAdd);
    }
  };

  const handleRemoveContractFile = () => {
    setContractError(null);
    setContractFile(null);
    setContractData(null);
  };

  const onClickAnalyzeContract = () => {
    let currentWriterDetails;
    if (emailToInvite) {
      let formattedISNI = null;
      if (suggestedISNI && isniRegex.test(suggestedISNI)) {
        const trimmed = suggestedISNI.replace(/\s/g, '');
        const sections = trimmed.match(/.{1,4}/g);
        formattedISNI = sections.join(' ');
      }

      currentWriterDetails = {
        user: existingUser || null,
        email: emailToInvite,
        suggestedSociety: getSocietyByName(suggestedSociety),
        suggestedIPI: ipiRegex.test(suggestedIPI) ? suggestedIPI : null,
        suggestedISNI: formattedISNI,
      };
    }
    handleAnalyzeContract(currentWriterDetails);
  };

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleLookupApiCall = (email) => {
    getArtistLookupWithEmail(email, -1, -1)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setError('');
            const existing = json.map((user) => ({
              id: user.accountId,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              imgURL: user.imgURL,
              isVerified: user.isVerified,
              email: user.email,
              society: user.societies[0] || null,
              ipi: user.ipi || null,
              isni: user.isni || null,
            }));
            setExistingUser(existing[0] || null);
            setSuggestedSociety(existing[0]?.society || contractData?.data?.writerPRO || null);
            setSuggestedIPI(existing[0]?.ipi || contractData?.data?.writerIPI || null);
            setSuggestedISNI(existing[0]?.isni || null);
            setIsSearching(false);
          });
        } else if (response.status === 202) {
          setExistingUser(null);
          setSuggestedSociety(contractData?.data?.writerPRO || null);
          setSuggestedIPI(contractData?.data?.writerIPI || null);
          setSuggestedISNI(null);
          setIsSearching(false);
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsSearching(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setIsSearching(false);
      });
  };

  const handleChangeEmailToInvite = (event) => {
    event.persist();
    const userInput = event.target.value;

    setExistingUser(null);
    setSuggestedISNI(null);
    setSuggestedIPI(null);

    if (validateEmail(userInput)) {
      setIsSearching(true);
      setEmailToInvite(userInput.toLowerCase());
      handleLookupApiCall(userInput.toLowerCase());
    } else {
      setEmailToInvite(null);
    }
  };

  const optimizedFn = useCallback(useDebounce(handleChangeEmailToInvite), [contractData]);

  const onClickContinue = () => {
    if (suggestedIPI && (!ipiRegex.test(suggestedIPI))) {
      setError('IPI number must be 9-11 digits');
      return;
    }
    if (suggestedISNI && (!isniRegex.test(suggestedISNI))) {
      setError('ISNI must be 16 digits');
      return;
    }
    const societyData = getSocietyByName(suggestedSociety);
    if (suggestedSociety && !societyData) {
      setError('Society not found');
      return;
    }

    setError('');
    setIsSaving(true);
    let formattedISNI = null;
    if (suggestedISNI) {
      const trimmed = suggestedISNI.replace(/\s/g, '');
      const sections = trimmed.match(/.{1,4}/g);
      formattedISNI = sections.join(' ');
    }
    const writerDetails = {
      user: existingUser || null,
      email: emailToInvite,
      suggestedSociety: societyData,
      suggestedIPI,
      suggestedISNI: formattedISNI,
    };
    setRecipient(writerDetails);
    setTimeout(() => {
      setIsSaving(false);
      setStep('terms');
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.25em" mt="0.5em" color="var(--panel-info-border)" />

      <Box>
        <InputLabel label="Contract AI (Optional)" />
        <SecondaryText mb="1em" mt="0.75rem" fontSize="0.813rem">
          {contractData ? `Contract analyzed! ${contractData.fieldsPopulated} field${contractData.fieldsPopulated > 1 && 's'} populated.` : 'We can populate fields using the songwriter agreement.'}
        </SecondaryText>
        {!contractData && (
          <DragAndDrop
            handleFilesAdded={handleAddContractFile}
            disabled={!!contractData || isAnalyzingContract}
            id="file-drop"
          />
        )}

        {contractFile && (
        <Text mb="0.875em" mt="0.5em" fontSize="0.813rem">
          File Added:
          {' '}
          {contractFile.name}
        </Text>
        )}

        {contractError && (
        <AlertMessage
          variant="negative"
          message={contractError}
          mb="1.5em"
          mt="1.5em"
        />
        )}

        {contractData ? (
          <BaseButton
            // mb="1.5em"
            btnText="Remove"
            fluid
            variant="secondary"
            onClick={handleRemoveContractFile}
          />
        ) : (
          contractFile && (
            <BaseButton
              // mb="1.5em"
              btnText="Analyze"
              fluid
              isLoading={isAnalyzingContract}
              onClick={onClickAnalyzeContract}
            />
          )
        )}
      </Box>

      <BreakLine mb="1.25em" mt="1.5em" color="var(--panel-info-border)" />

      <Box>
        <InputLabel label="Enter Writer Email" />
        <Box display="flex" flexDirection="row-reverse" alignItems="center" mb="1.5em" position="relative">
          <BaseInput
            ref={searchRef}
            id="userTypeAhead"
            name="userTypeAhead"
            placeholder="songwriter@email.com"
            onChange={optimizedFn}
            type="email"
            fluid
            required
            autoComplete="off"
          />
          <Box style={{ position: 'absolute', right: 16 }}>
            {(isSearching) && <Spinner variant="fieldLoader" size="1.125em" />}
          </Box>
        </Box>
      </Box>

      {(emailToInvite && !isSearching) && (
        <Fragment>
          {!existingUser?.id ? (
            <Box display="flex" alignItems="center">
              <Box mr="0.75em">
                <MailWedge />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.875rem">
                  New User
                </Text>
                <Text fontSize="0.813rem">Writer will register to accept your invitation.</Text>
              </Box>
            </Box>
          ) : (
            <Box display="flex" mb="1.5em" alignItems="center">
              <Box mr="0.75em">
                <Avatar size="3em" mb="1em" imgURL={existingUser.imgURL} isVerified={existingUser.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.75rem">
                  Registered User
                </Text>
                <Text>
                  {existingUser.firstName}
                  {' '}
                  {existingUser.lastName}
                </Text>
              </Box>
            </Box>
          )}

          <Box>
            <BreakLine mb="1.5em" mt="1em" color="var(--panel-info-border)" />
            <Text mb="1.5em">
              {existingUser?.id
                ? 'If you wish to add or suggest edits to this writer\'s information, you can enter them here.'
                : 'Add suggested identifiers for your writer\'s profile in Switchchord.'}
            </Text>
            <BaseTypeAhead
              label="Writer Collection Society (Optional)"
              id="societies"
              data={WriterSocieties}
              nameValue="name"
              handleChange={(data) => setSuggestedSociety(data)}
              initialValue={suggestedSociety || ''}
            />
            <InputLabel label="Writer Name IPI (Optional)" />
            <BaseInput
              ref={inputIPI}
              id="ipi"
              name="ipi"
              placeholder="Enter 9-11 Digit IPI"
              defaultValue={suggestedIPI || ''}
              onChange={(e) => setSuggestedIPI(e.target.value)}
              pattern="[0-9]+"
              maxLength="11"
              minLength="9"
              fluid
              type="text"
              inputMode="decimal"
              mb="1.5em"
              autoComplete="off"
            />
            <InputLabel label="Writer ISNI (Optional)" />
            <MaskedInput
              ref={inputISNI}
              maskGenerator={maskGenerator}
              className="masked-input"
              value={suggestedISNI || ''}
              onChange={setSuggestedISNI}
              id="isni"
              name="isni"
              placeholder="Enter 16 Digit ISNI"
              maxLength="19"
              minLength="19"
              type="text"
              inputMode="decimal"
              autoComplete="off"
            />
          </Box>

          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <Fragment>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
            <Box display="flex" justifyContent="flex-end">
              <BaseButton
                mb="1.5em"
                btnText="Continue"
                onClick={onClickContinue}
                disabled={isSaving}
                isLoading={isSaving}
                fluid
                decoration="arrow"
              />
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Box>
  );
}

AddWriterDetails.propTypes = {
  contractFile: PropTypes.object,
  setContractFile: PropTypes.func.isRequired,
  contractData: PropTypes.object,
  setContractData: PropTypes.func.isRequired,
  isAnalyzingContract: PropTypes.bool.isRequired,
  handleAnalyzeContract: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setRecipient: PropTypes.func.isRequired,
  recipient: PropTypes.object,
};

AddWriterDetails.defaultProps = {
  contractFile: null,
  contractData: null,
  recipient: null,
};

export default AddWriterDetails;
